.dashboard-main-div{
 display: flex;
 flex-direction: column;
 width: 100%;
 align-items: center;
 gap: 20px;
}
.dashboard-row{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}


@media (min-width: 768px) and (max-width: 1024px) {
    
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
   
  }
  