.city-text-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}

.city-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .city-text-field {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .city-text-field {
    display: flex;
    flex-direction: column;
  }
}
