.order-main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.order-details-row {
  display: flex;
 flex-direction: row;
 width: 100%;
 gap: 10px;

}
.order-details-element {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  width: 50%;
}
.order-addon-h6{
    font-size: 0.8rem;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .order-details-row {
        display: flex;
       flex-direction: column;
       width: 100%;
       gap: 10px;
      
      }
      .order-details-element {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-start;
        width: 100%;
      }

}

@media (min-width: 320px) and (max-width: 480px) {
    .order-details-row {
        display: flex;
       flex-direction: column;
       width: 100%;
       gap: 10px;
      
      }
      .order-details-element {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-start;
        width: 100%;
      }

}
