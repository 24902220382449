.approvals-main-div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .approvals-details-row {
    display: flex;
   flex-direction: row;
   width: 100%;
   gap: 10px;
  
  }
  .approvals-details-element {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
    width: 50%;
  }
  .approvals-addon-h6{
      font-size: 0.8rem;
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
      .approvals-details-row {
          display: flex;
         flex-direction: column;
         width: 100%;
         gap: 10px;
        
        }
        .approvals-details-element {
          display: flex;
          flex-direction: row;
          gap: 20px;
          justify-content: flex-start;
          width: 100%;
        }
  
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
      .approvals-details-row {
          display: flex;
         flex-direction: column;
         width: 100%;
         gap: 10px;
        
        }
        .approvals-details-element {
          display: flex;
          flex-direction: row;
          gap: 20px;
          justify-content: flex-start;
          width: 100%;
        }
  
  }
  