.customers-text-field {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .customers-text-field-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .customers-toggle-div{
      display: flex;
      gap: 100px;
      /* min-width: 500px; */
      width: 100%;
      align-items: center;
      justify-content: center;
      min-height: 150px;
  }
  .customer-image-choose{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  
  @media (min-width: 768px) and (max-width: 1024px) {
    .customers-text-field {
      display: flex;
      flex-direction: column;
    }
  
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .customers-text-field {
      display: flex;
      flex-direction: column;
    }
    .customers-toggle-div{
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
     
  }
  
  }