.register-text-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.register-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.subscription-plan-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.plan-div{
  display: flex;
  gap: 5px;
  
}
.sub-plan{
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #A9BCB0;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
}
.plan-name{
  font-size: 0.7rem;
}
.plan-amt{
  font-size: 0.9rem;
}
.plan-des{
  font-size: 0.8rem;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .register-text-field {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .register-text-field {
    display: flex;
    flex-direction: column;
  }
}
